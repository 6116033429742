import "./styles/Prueba.css"
import "animate.css/animate.compat.css"
import ScrollAnimation from 'react-animate-on-scroll';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

function Prueba() {
    const [show, isShow] = useState(true);
    const navigate = useNavigate();
    const goToBook = ()=> {
        navigate("/book")
    }
    return (
        <>
            {
                (false && show) && <article className='interest'>
                    {
                        <div className='overlay-background'>
                            <div className='overlay-background' style={{ "position": "absolute", "zIndex": "-1", "opacity": "0" }} onClick={() => { isShow(false) }} />
                            <video autoPlay muted loop onClick={goToBook} style={{cursor:"pointer"}}>
                                <source src={process.env.PUBLIC_URL + "/img/libro.mp4"}></source>
                            </video>
                        </div>
                    }
                </article>
            }
            <ScrollAnimation animateIn="slideInDown" animateOut='fadeOut' offset={10} animatePreScroll={true}>
                <section>
                    <div className='first-page'>
                        <div>
                            <p>
                                <br />
                                <br />
                                <br />
                            </p>
                            <div>
                                <a href="/book" style={{ color: "white" }}>
                                    <div className='button-green'>
                                        <p>Conoce Más</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </ScrollAnimation>
            <ScrollAnimation animateIn="pulse" animateOut='fadeOut' offset={50} animatePreScroll={true}>
            <section className="book">
                <img className="cover" src={process.env.PUBLIC_URL + "/img/book.jpg"}/>
                <div className="text-box">
                    <h1>ECOGRAFÍA FETAL LIBRO</h1>
                    <div >
                        <p>
                            Los avances tecnológicos han permitido una favorable evolución de los alcances clínicos de la Ecografía Fetal como disciplina médica.
                            Con la mejoría en la resolución de la imagen ecográfica, se ha incrementado también la capacidad de evaluar nuevas estructuras intrafetales
                            y lograr comprender mejor la anatomía y desarrollo fetal. Aunque existen otros textos relacionados con la Ecografía Fetal
                        </p>
                    </div>
                    <div>
                        <p>
                            Son escasos los que incluyen imágenes de alta resolución de las diferentes estructuras anatómicas del feto. Esta obra ha sido realizada por
                            especialistas en Medicina Materno Fetal que colaboran con la Fundación Medicina Fetal México e incluye una gran variedad de imágenes ecográficas
                            obstétricas de máxima resolución y calidad, así como guías técnicas concisas para lograr una adecuada adquisición e interpretación de los diferentes
                            planos ecográficos recomendados durante la evaluación ultrasonográfica del feto.
                        </p>
                    </div>
                    <div>
                        <p>
                            La obra ha sido especialmente diseñada para acortar la curva de aprendizaje a todos los médicos de diferentes disciplinas relacionadas
                            con el Ultrasonido Obstétrico y tiene el objetivo de convertirse en la guía principal para reconocer la normalidad de la Ecografía Fetal
                            a diferentes edades gestacionales
                        </p>
                    </div>
                    <a className="button button-cta" href="/book">¡Comprar ahora!</a>
                </div>
            </section>
            </ScrollAnimation>
            <ScrollAnimation animateIn="rotateInDownLeft" animateOut='fadeOut' offset={50} animatePreScroll={true}>
                <section>
                    <div className='second-page'>
                        <h1>Misión</h1>
                        <p>
                            En la Fundación Medicina Fetal México, nos dedicamos a intervenir en una etapa crucial <br />
                            del desarrollo fetal, sentando las bases para un futuro saludable. Nuestro enfoque <br />
                            humanizado pone en primer lugar el bienestar y los derechos de las madres y sus <br />
                            bebés.
                        </p>
                        <p>
                            Nos esforzamos por proporcionar diagnósticos precisos, tratamientos avanzados y <br />
                            acceso equitativo a la tecnología de vanguardia, garantizando que cada familia, <br />
                            independientemente de su situación económica o geográfica, tenga acceso a la mejor <br />
                            atención médica posible durante esta etapa fundamental.
                        </p>
                        <p>
                            A través de nuestro trabajo, buscamos reducir las complicaciones en el embarazo y <br />
                            asegurar los mejores resultados para cada bebé, dando a cada familia la oportunidad <br />
                            de un comienzo saludable y prometedor.
                        </p>
                        <p>
                            Cada latido cuenta, cada momento importa
                        </p>
                    </div>
                </section>
            </ScrollAnimation>
            <ScrollAnimation animateIn="pulse" animateOut='fadeOut' offset={50} animatePreScroll={true}>
                <section>
                    <div className='third-page'>
                        <p>
                            Cuidado y Esperanza: <br />
                            Servicios Avanzados en <br />
                            Medicina Fetal a Tu alcance<br />
                        </p>
                        <div className='row'>
                            <div className='paragraph'>
                                <h3>Paquete Básico de Ultrasonido Fetal</h3>
                                <p>
                                    Descubre nuestro servicio de ultrasonido, creado para<br />
                                    acompañarte y monitorear el crecimiento de tu bebé.<br />
                                    en una etapa concreta. Utilizando la última tecnología<br />
                                    y un trato personalizado, te brindamos evaluaciones<br />
                                    detalladas para asegurar tu tranquilidad y el bienestar<br />
                                    de tu bebé, en un entorno acogedor que fomenta<br />
                                    y fortalece el vínculo maternal<br />
                                </p>
                                <div className='pink-button'>Explorar</div>
                            </div>
                            <div className='paragraph'>
                                <h3>Paquete Integral de Ultrasonido Fetal</h3>
                                <p>
                                    Este paquete especial te acompaña en cada paso de <br />
                                    tu embarazo, ofreciendo un seguimiento completo y <br />
                                    cariñoso del desarrollo de tu bebé. Con tecnología de <br />
                                    ultrasonido de vanguardia, nos aseguramos de <br />
                                    proporcionar evaluaciones detalladas y precisas en <br />
                                    cada etapa, brindándote tranquilidad y apoyo <br />
                                    constante
                                </p>
                                <div className='pink-button'>Explorar</div>
                            </div>
                            <div className='paragraph'>
                                <p>
                                    Tu apoyo es fundamental. Gracias a tu colaboración,<br />
                                    tenemos la capacidad de ofrecer avanzados servicios<br />
                                    de ultrasonido y cirugías fetales a costos<br />
                                    significativamente más accesibles. Nuestro equipo de<br />
                                    expertos en medicina materno-fetal proporciona una<br />
                                    atención de primera calidad a todas las familias,<br />
                                    prestando especial atención a aquellas en situaciones<br />
                                    de vulnerabilidad. Juntos, nos comprometemos a que<br />
                                    cada madre y cada bebé reciban los cuidados de alta<br />
                                    calidad que merecen, sin distinción económica.<br />

                                    Tu participación es crucial y marca la diferencia.
                                </p>
                            </div>

                        </div>
                    </div>
                </section>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn" animateOut='fadeOut' offset={10} animatePreScroll={true}>
                <section>
                    <div className='four-page'>
                        <h1>Proyectos</h1>
                        <div className='row'>
                            <div className='card-container'>
                                <div className='card-img'>
                                    <img src={process.env.PUBLIC_URL + "/img/img_03.png"} className="card-gray" style={{ maxWidth: "800px" }} />
                                    <p style={{ fontWeight: "400", fontSize: "25px" }}>Diagnósticos y Tratamientos Avanzados</p>
                                    <p>
                                        empleamos tecnología de vanguardia para garantizar diagnósticos precisos y tratamientos innovadores.<br />
                                        Nuestros ultrasonidos fetales avanzados y cirugías especializadas abordan una amplia gama de patologías
                                    </p>
                                    <p className='green-btn'>Conoce más</p>
                                </div>
                                <div className='card-img'>
                                    <img src={process.env.PUBLIC_URL + "/img/img_04.png"} className="card-gray" style={{ maxWidth: "800px" }} />
                                    <p style={{ fontWeight: "400", fontSize: "25px" }}>Investigación para la Innovación</p>
                                    <p>
                                        Participan activamente en la investigación médica para desarrollar nuevas técnicas y tratamientos en medicina
                                        fetal, colaborando con redes globales para impulsar la mejora continua de los servicios de salud materno-infantil.
                                    </p>
                                    <p className='green-btn'>Conoce más</p>
                                </div>
                            </div>
                            <div className='card-container'>
                                <div className='card-img'>
                                    <img src={process.env.PUBLIC_URL + "/img/img_01.png"} className="card-gray" style={{ maxWidth: "800px" }} />
                                    <p style={{ fontWeight: "400", fontSize: "25px" }}>Educación y Capacitación</p>
                                    <p>
                                        A través de becas y programas de formación, capacitan a la próxima generación de profesionales médicos en el
                                        campo de la medicina fetal, asegurando la continuidad de la innovación y la calidad en la atención médica.
                                    </p>
                                    <p className='green-btn'>Conoce más</p>
                                </div>
                                <div className='card-img'>
                                    <img src={process.env.PUBLIC_URL + "/img/img_02.png"} className="card-gray" style={{ maxWidth: "800px" }} />
                                    <p style={{ fontWeight: "400", fontSize: "25px" }}>Divulgación y Educación para la Comunidad</p>
                                    <p>
                                        Proveen información vital sobre salud prenatal y neonatal, y facilitan un foro interactivo donde las familias pueden
                                        recibir orientación directa de expertos.
                                    </p>
                                    <p className='green-btn'>Conoce más</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ScrollAnimation>
            <ScrollAnimation animateIn="bounceIn" animateOut='fadeOut' offset={50} animatePreScroll={true}>
            </ScrollAnimation>
            <section>
                <div className='five-page'>
                    <ScrollAnimation animateIn="bounceIn" animateOut='fadeOut' offset={50} animatePreScroll={true}>
                        <div className='row'>
                            <div className='column'>
                                <img src={process.env.PUBLIC_URL + "/img/img_05.png"} style={{ maxWidth: "500px" }} />
                                <br />
                                <p style={{ fontWeight: "500", fontSize: "25px", textAlign: "center" }}>
                                    Equipo de<br />
                                    "Alianza por la Salud Materna"
                                </p>
                                <br />
                                <p>
                                    Colaborar con la Fundación Medicina Fetal México nos ha <br />
                                    permitido llegar a más familias necesitadas. Su dedicación y <br />
                                    enfoque integral son inspiradores. Juntos, estamos construyendo <br />
                                    un futuro más saludable para las generaciones venideras.
                                </p>
                                <p>
                                    Leer más
                                </p>
                            </div>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="bounceIn" animateOut='fadeOut' offset={50} animatePreScroll={true}>
                        <div className='row'>
                            <div className='column'>
                                <img src={process.env.PUBLIC_URL + "/img/img_06.png"} style={{ maxWidth: "500px" }} />
                                <br />
                                <p style={{ fontWeight: "500", fontSize: "25px", textAlign: "center" }}>
                                    Laura Ramírez
                                </p>
                                <br />
                                <p>
                                    Gracias a la Fundación Medicina Fetal México, pude enfrentar mi <br />
                                    embarazo de alto riesgo con valentía y confianza. Su atención <br />
                                    personalizada y compasiva marcó la diferencia en mi vida y la de <br />
                                    mi bebé.
                                </p>
                                <p>
                                    Leer más
                                </p>
                            </div>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="bounceIn" animateOut='fadeOut' offset={50} animatePreScroll={true}>
                        <div className='row'>
                            <div className='column'>
                                <img src={process.env.PUBLIC_URL + "/img/img_07.png"} style={{ maxWidth: "500px" }} />
                                <br />
                                <p style={{ fontWeight: "500", fontSize: "25px", textAlign: "center" }}>
                                    Dr. Carlos Guzmán
                                </p>
                                <br />
                                <p>
                                    Como médico formado en el Centro de Medicina Fetal México, me <br />
                                    enorgullece ser parte de un equipo que transforma vidas. Ver la <br />
                                    sonrisa de las madres al recibir atención especializada es mi mayor <br />
                                    recompensa.
                                </p>
                                <p>
                                    Leer más
                                </p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            </section>
            <ScrollAnimation animateIn="heartBeat" animateOut='backOutLeft' offset={15} animatePreScroll={true}>
                <section>
                    <div className='six-page'>CADA LATIDO CUENTA</div>
                    <div className='logo-anim'>
                        <img src={process.env.PUBLIC_URL + "/img/logo192.png"} style={{ maxWidth: "300px" }} />
                    </div>
                </section>
            </ScrollAnimation>
        </>
    );
}

export default Prueba;